import 'reflect-metadata';
import {Container} from 'inversify';
import {httpApi} from '../../common/infra/http';
import { CreateUseCase } from '../application/create.use.case';
import { BillHttpGateway } from './bill.http.gateway';
import { DeleteUseCase } from '../application/delete.use.case';

export const Registry = {
    AxiosAdapter: Symbol.for('AxiosAdapter'),
    BillGateway: Symbol.for('BillGateway'),
    CreateUseCase: Symbol.for('CreateUseCase'),
    DeleteUseCase: Symbol.for('DeleteUseCase'),
};

export const container = new Container();

//########## HTTP
container.bind(Registry.AxiosAdapter).toConstantValue(httpApi);

//#region ########## GATEWAYS
container.bind(Registry.BillGateway).toDynamicValue((context) => {
    return new BillHttpGateway(context.container.get(Registry.AxiosAdapter));
});

//#endregion

//#region ########## USE CASES
container.bind(Registry.CreateUseCase).toDynamicValue((context) => {
    return new CreateUseCase(context.container.get(Registry.BillGateway));
});

container.bind(Registry.DeleteUseCase).toDynamicValue((context) => {
    return new DeleteUseCase(context.container.get(Registry.BillGateway));
});
//#endregion

//#region ######### CONTAINERS GET
const bill = {
    create: container.get<CreateUseCase>(Registry.CreateUseCase),
    delete: container.get<DeleteUseCase>(Registry.DeleteUseCase),
};
export default bill;
//#endregion

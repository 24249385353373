import { useItemsContext } from "@/@presentation/contexts/schedulesContext";
import { Modal, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Notification } from '@presentation/components/common';
import { Button } from "../Button";
import {Modal as AntModal, Typography, Form} from 'antd';
import {Button as AntdButton} from 'antd'
import {Payer} from '@/@presentation/modules/schedule/pages/home/payer';
import customer from '@/@core/modules/customer/infra/container.registry';
import money from "@/@core/modules/common/infra/money";
import date from '@core/modules/common/infra/date';
import {formatStatus, formatTaxNoteNumber} from "@/@core/modules/schedule/domain/schedule.entities";
import cataGadp from '@/@core/modules/validateCataGadp/infra/container.registry';

const ButtonsBar = (props: {
    selectedItems: any[]
    nfNumber: number
    setSelectedItems: React.Dispatch<React.SetStateAction<any[]>>
    removeItem: (id: any, callback?: 'nf' | 'payment' | '') => void
    executeFunction?: string
}) => {
    const navigate = useNavigate()
    const { setOrigin } = useItemsContext();
    const { items, setItems } = useItemsContext()
    const [showModalPayer, setShowModalPayer] = useState(false)
    const [isReload, setReload] = React.useState(true);
    const [pagador, setPagador] = useState('')
    const [dataPayerError, setDataPayerError] = useState(false);
    const [relatedModalOpen, setRelatedModalOpen] = useState(false)
    const [customerWarningModalOpen, setCustomerWarningModalOpen] = useState(false)
    const [relatedInvoices, setRelatedInvoices] = useState<any[]>([])
    const [showErrorMultipleCompanies, setShowErrorMultipleCompanies] = useState(false)
    const [selectedCompanies, setSelectedCompanies] = useState<any[]>([])
    const [redirectFor, setRedirectFor] = useState<'nf' | 'payment' | ''>('')

    useEffect(() => {
        if (props?.executeFunction == 'nf') {
            showNfProcess({
                ignoreCataGadp: true
            })
        }

        if (props?.executeFunction == 'payment') {
            showPaymentProcess({
                ignoreCataGadp: true
            })
        }
    }, [props?.executeFunction]);

    const getRelatedInvoices = async () => {
        const scheduleIds = props.selectedItems.map( item => item.id )
        const invoiceNumbers = props.selectedItems.map( item => item.invoiceNumber )
        const related = await customer.relatedInvoices.execute({
            scheduleIds: scheduleIds,
            invoiceNumbers: invoiceNumbers
        })
        
        return related.map((item) => ({
            ...item,
            value: money.formatCurrency(item?.value),
            total: money.formatCurrency(item?.total),
            amountPaid: money.formatCurrency(item?.amountPaid),
            amountFeesTable: money.formatCurrency(item?.amountFeesTable),
            amountSpecialHourFeesTable: money.formatCurrency(item?.amountSpecialHourFeesTable),
            procedureDate: date.formatCurrency(item?.procedureDate),
            scheduleDate: date.formatCurrency(item?.scheduleDate),
            status: formatStatus(item?.status),
            taxNoteNumber: formatTaxNoteNumber(item?.taxNoteNumber),
        }));
    }

    const getStringRelatedInvoice = () => {
        return relatedInvoices.map((related, index) => (
            <span key={index}>
            [<b>{related.id}</b>] <b>{related.patient.toUpperCase()}</b>
            <br />
            </span>
        ));
    };

    const getStringCustomerWarning = () => {
        const invalids = props.selectedItems.filter(item => 
            !item?.paymentCustomer?.name?.trim() ||
            !item?.paymentCustomer?.registry_code?.trim() ||
            !item?.paymentCustomer?.city?.trim() ||
            (item?.paymentCustomer?.id <= 0) ||
            !item?.paymentCustomer?.neighborhood?.trim() ||
            !item?.paymentCustomer?.state?.trim() ||
            !item?.paymentCustomer?.street?.trim() ||
            !item?.paymentCustomer?.zipcode?.trim() ||
            !item?.paymentCustomer?.number?.trim()
        )
        return invalids.map((item, index) => (
            <span key={index}>
            [<b>{item.id}</b>] <b>{item.patient.toUpperCase()}</b>
            <br />
            </span>
        ));
    };

    const validarTomadoresInvalidos = () => {
        const invalids = props.selectedItems.filter(item => 
            !item?.paymentCustomer?.name?.trim() ||
            !item?.paymentCustomer?.registry_code?.trim() ||
            !item?.paymentCustomer?.city?.trim() ||
            (item?.paymentCustomer?.id <= 0) ||
            !item?.paymentCustomer?.neighborhood?.trim() ||
            !item?.paymentCustomer?.state?.trim() ||
            !item?.paymentCustomer?.street?.trim() ||
            !item?.paymentCustomer?.zipcode?.trim() ||
            !item?.paymentCustomer?.number?.trim()
        )
        //Detectar possiveis tomadores inválidos
        if(invalids && invalids.length > 0){
            return true
        }
        //Validação para itens antecipados
        const antecipado = props.selectedItems.filter(item => item.checkIn === null).length
        if (antecipado >= 1) {       
            return true
        }
        return false
    }
    
    const handleSubmitFromRelatedInvoicesModal = () => {
        props.setSelectedItems([...props.selectedItems, ...relatedInvoices]);
        setOrigin('payment')
        setItems(props.selectedItems)
        setShowModalPayer(true)
    }
    
    const handleSubmitFromCustomerWarningModal = () => {
        if (props.selectedItems.length > 1)
            setShowModalPayer(true)
        else
        navigate('/paymentprocess')
    }

    const verifyCataOrGadp = async () => {
        const verifyCompany = props.selectedItems.map(async (item) => {
            const resVerify = await cataGadp.verify.execute(item.id)
            return resVerify === 3 ? {company: 'cata', id: item.id} : {company: 'gadp', id: item.id}
        })

        const company = await Promise.all(verifyCompany)

        setSelectedCompanies(company)

        const companyUnique = company.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.company === item.company
            ))
        )

        if (companyUnique.length > 1) {
            setShowErrorMultipleCompanies(true)

            return true
        }

        return false
    }

    const showPaymentProcess = async (payload?: any) => {
        setRelatedInvoices([])
        const itemsWithZeroTotal = props.selectedItems.filter(item => {
            return item?.total.replace(/\D/g, '') === '000';
        })

        if (props.selectedItems.filter(item => item?.schedulePaymentTypeId === 5).length > 0) {
            Notification.error
            ({
                message: 'Não é possível realizar o Pagamento para items em Cortesia!',
                duration: 3,
            })

            return
        }

        if (itemsWithZeroTotal.length > 0) {
            Notification.error({
                message: 'Não é possível realizar o Pagamento com valor zerado!',
                duration: 3,
            });
            return;
        }
            
        if (props.selectedItems.filter(item => item?.statusBilling === 'paid').length > 0) {
            Notification.error({
                message: 'Não é possível realizar o Pagamento de Itens já pagos!',
                duration: 3,
            })
            return
        }
            
        if (props.selectedItems.filter(item => item?.status === 'CANCELADO').length > 0) {
            Notification.error({
                message: 'Não é possível realizar o Pagamento de Item Cancelado!',
                duration: 3,
            })
            return
        }

        if (props.selectedItems.filter(item => item?.statusBilling === 'pending').length > 0) {
            Notification.error ({
                message: 'Não é possível realizar o Pagamento de Itens com pagamentos pendentes!',
                duration: 3,
            })
            return
        }

        // Tomador precisa estar preenchido (já estará quando a NF for emitida antes, e agora é obrigatório para pagamentos antecipados/lote)
        const names = props.selectedItems.map(item => item?.paymentCustomer?.name)
        const uniqueNames = [...new Set(names)]

        const itemsWithIncompleteData = props.selectedItems.filter(
            item => item?.paymentCustomer?.id === 0
        );
        
        const hasIncompleteData = itemsWithIncompleteData.length > 0;
        const hasMultipleUniqueNames = uniqueNames.length > 1;
        
        if (hasIncompleteData) {
            const ids = itemsWithIncompleteData.map(item => item.id);
        
            Notification.error({
                message: 'Dados do tomador incompletos',
                description: `Por favor, preencha os dados do tomador da NF antes de seguir para pagamento.\nID's: ${ids.join(', ')}`,
                duration: 3,
            });
        
            return;
        }

        if (hasMultipleUniqueNames) {
            const ids = itemsWithIncompleteData.map(item => item.id);
        
            Notification.error({
                description: `Não é possível efetuar o pagamento em lote com tomadores diferentes!`,
                duration: 3,
            });
        
            return;
        }

        if (props.selectedItems.length > 1) {
            //Validação para itens antecipados
            const antecipado = props.selectedItems.filter(item => item.checkIn === null).length
            if (antecipado >= 1) {
                Notification.error({
                    message: 'Pagamento Antecipado',
                    description: `Não é possível realizar o Pagamento Antecipado de mais de 1 item!`,
                    duration: 3,
                })
                return
            }
            //Validação para tipos de tomadores diferentes
            const isCPF = (registryCode: string) => registryCode.length === 11;
            const isCNPJ = (registryCode: string) => registryCode.length === 14;
            const cpfItems = props.selectedItems.filter(item => isCPF(item.paymentCustomer.registry_code));
            const cnpjItems = props.selectedItems.filter(item => isCNPJ(item.paymentCustomer.registry_code));
            const hasDifferentPayers = cpfItems.length > 0 && cnpjItems.length > 0;
            if(hasDifferentPayers){
                Notification.error({
                    message: 'Tomadores Diferentes',
                    description: `Não é possível realizar o Pagamento em lote com tomadores diferentes!`,
                    duration: 5,
                })
                return
            }


            // start PALIATIVO -- REMOVER ABAIXO
             
            // Não permitir pagamento em lote com tomadores diferentes
            if(props.selectedItems.some(item => item.paymentCustomer.registry_code !== props.selectedItems[0].paymentCustomer.registry_code)){
                Notification.error({
                    message: 'Tomadores Diferentes',
                    description: 'Não é possível realizar o Pagamento em lote com tomadores diferentes.',
                    duration: 5,
                })
                return
            }
            // end PALIATIVO -- REMOVER ACIMA


            //Validação status NF            
            if (props.selectedItems.some(item => item.statusInvoice !== props.selectedItems[0].statusInvoice)){
                Notification.error
                ({
                    message: `Status NF`,
                    description: 'Não é possível realizar o pagamento de itens com status de nota fiscal diferentes',
                    duration: 3,
                })
                return     
            }

            //valida parcelas
            if (props.selectedItems.some(item => item.paymentInstallmentsNumber !== props.selectedItems[0].paymentInstallmentsNumber)){
                Notification.error
                ({
                    message: `Parcelas diferentes`,
                    description: 'Não é possível realizar o pagamento de itens com número de parcelas diferentes',
                    duration: 3,
                })
                return     
            }
            //Validar se métodos de pagamentos são iguais (caso nota emitida)
            if (props.selectedItems[0].invoiceNumber !== '' && props.selectedItems.some(item => item.methodCode !== props.selectedItems[0].methodCode)){
                //Validar status NF
                Notification.error
                ({
                    message: 'Não é possível realizar o pagamento de itens com notas emitidas com métodos de pagamento diferentes',
                    duration: 3,
                })
                return     
            }
        }    

        setRedirectFor('payment')
        if (!payload?.ignoreCataGadp && await Promise.resolve(verifyCataOrGadp())) {
            setShowErrorMultipleCompanies(true)
            return;
        }
        
        //Validar se existem mais procedimentos atrelados a alguma nota selecionada
        const invoices = await getRelatedInvoices()
        setRelatedInvoices(invoices)

        if (props.selectedItems.length === 1) {
            //Set Payer
            props.selectedItems.forEach( item => { item.payerCustomer = {
                id: props.selectedItems[0].paymentCustomer.id,
                name: props.selectedItems[0].paymentCustomer.name,
                email: props.selectedItems[0].paymentCustomer.email,
                registry_code: props.selectedItems[0].paymentCustomer.registry_code,
                code: props.selectedItems[0].paymentCustomer.registry_code,
                address: {
                    zipcode: props.selectedItems[0].paymentCustomer.zipcode,
                    city: props.selectedItems[0].paymentCustomer.city,
                    country: props.selectedItems[0].paymentCustomer.country,
                    neighborhood: props.selectedItems[0].paymentCustomer.neighborhood,
                    state: props.selectedItems[0].paymentCustomer.state,
                    number: props.selectedItems[0].paymentCustomer.number,
                    street: props.selectedItems[0].paymentCustomer.street,
                }
            }})
            //Se tiver notas relacionadas
            if (invoices.length > 0){
                setRelatedModalOpen(true)
                //return
            }else{
                setOrigin('payment')
                setItems(props.selectedItems);
                if(validarTomadoresInvalidos()){
                    setCustomerWarningModalOpen(true)
                }else{
                    navigate('/paymentprocess', 
                        { state: {
                            payer: pagador,
                        }}
                    )
                }         
            }
            //
            
        }
        else if (props.selectedItems.length > 1) {
            if (props.selectedItems.some(item => item.receiverName !== props.selectedItems[0].receiverName)){
                //Tomadores diferentes
                props.selectedItems.forEach( item => { item.payerCustomer = undefined })
                
            }else{
                //Tomadores iguais
                props.selectedItems.forEach( item => { item.payerCustomer = {
                    id: props.selectedItems[0].paymentCustomer.id,
                    name: props.selectedItems[0].paymentCustomer.name,
                    email: props.selectedItems[0].paymentCustomer.email,
                    registry_code: props.selectedItems[0].paymentCustomer.registry_code,
                    code: props.selectedItems[0].paymentCustomer.registry_code,
                    address: {
                        zipcode: props.selectedItems[0].paymentCustomer.zipcode,
                        city: props.selectedItems[0].paymentCustomer.city,
                        country: props.selectedItems[0].paymentCustomer.country,
                        neighborhood: props.selectedItems[0].paymentCustomer.neighborhood,
                        state: props.selectedItems[0].paymentCustomer.state,
                        number: props.selectedItems[0].paymentCustomer.number,
                        street: props.selectedItems[0].paymentCustomer.street,
                    }
                }})
            }
            if (invoices.length > 0){
                setRelatedModalOpen(true)
                //return
            }else{
                setOrigin('payment')
                setItems(props.selectedItems)
                if(validarTomadoresInvalidos()){
                    setCustomerWarningModalOpen(true)
                }else{
                    setShowModalPayer(true)
                }  
                
            }
            
        }      
    }

    const showNfProcess = async (payload?: any) => {
        const itemsWithZeroTotal = props.selectedItems.filter(item => {
            return item?.total.replace(/\D/g, '') === '000';
        })

        if (itemsWithZeroTotal.length > 0) {
            Notification.error({
                message: 'Não é possível solicitar NF com valor zerado!',
                duration: 3,
            });
            return;
        }

        if (props.selectedItems.filter(item => item?.schedulePaymentTypeId === 5).length > 0) {
            Notification.error
                ({
                    message: 'Não é possível emitir a NF para items em Cortesia!',
                    duration: 3,
                })

            return
        }

        const procedimentosEmAberto = props.selectedItems.filter(item => item.checkIn === null || item.checkOut === null).length

        if (procedimentosEmAberto > 0) {
            Notification.error
                ({
                    message: 'Não é possível emitir a NF com procedimentos em aberto!',
                    duration: 5,
                })

            return
        }

        if (props.selectedItems.some(item => item.receiverName !== props.selectedItems[0].receiverName)) {

            Notification.error
                ({
                    message: 'Não é possível solicitar a emissão em lote com tomadores diferentes!',
                    duration: 5,
                })

            return
        }

        if (props.selectedItems.filter(item => item?.status === 'CANCELADO').length > 0) {

            Notification.error
                ({
                    message: 'Não é possível solicitar NF para Item Cancelado!',
                    duration: 3,
                })

            return
        }

        if (props.selectedItems.filter(item => item?.advancePayment === true && item?.statusBilling !== 'canceled' && item?.statusBilling !== 'cancelled').length > 0) {

            Notification.error
                ({
                    message: 'Não é possível solicitar NF para Item com Pagamento Antecipado!',
                    duration: 3,
                })

            return
        }

        if (props.selectedItems.filter(item => item?.statusBilling === 'pending').length > 0) {
            Notification.error ({
                message: 'Não é possível solicitar nota fiscal de Itens com pagamentos pendentes!',
                duration: 3,
            })
            return
        }

        if (
            props.selectedItems.filter(item => item?.statusInvoice === 'Solicitado').length > 0 ||
            props.selectedItems.filter(item => item?.statusInvoice === 'Emitido').length > 0
        ) {

            Notification.error
                ({
                    message: 'Não é possível solicitar NF para itens já enviados!',
                    duration: 3,
                })

            return
        }

        setRedirectFor('nf')
        if (!payload?.ignoreCataGadp && await Promise.resolve(verifyCataOrGadp())) {
            setShowErrorMultipleCompanies(true)
            return;
        }

        if (props.selectedItems.length > 0) {
            setOrigin('payment')
            setItems(props.selectedItems);
            navigate(`/nfprocess`);
        }
    }

    const selectCompany = (company: 'CATA' | 'GADP') => {
        const items = selectedCompanies.filter((item) => item.company !== company.toLocaleLowerCase()).map(i => i.id);
        
        if (items.length > 0) {
            props.removeItem(items, redirectFor);
        }

        Notification.success({
            message: `Itens da empresa ${company} selecionados com sucesso!`,
            duration: 3,
        });

        setShowErrorMultipleCompanies(false);
    }
    

    return (
        <div style={{ marginTop: 20 }}>
            <Modal
                open={showErrorMultipleCompanies}
                centered={true}
                closable={false}
                footer={[
                    <Button
                        key="cata"
                        text="CATA"
                        onClick={() => selectCompany('CATA')}
                    />,
                    <Button
                        key="gadp"
                        text="GADP"
                        onClick={() => selectCompany('GADP')}
                    />
                ]}
                onOk={() => setShowErrorMultipleCompanies(false)}
                title={<h2 style={{ textAlign: 'center', fontSize: '1.5rem' }}>Aviso</h2>}
            >
                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <p style={{ fontSize: '1.1rem', margin: '5px 0' }}>
                    Não é possível prosseguir com itens de empresas diferentes.
                    </p>
                    <p style={{ fontSize: '1.1rem', margin: '5px 0' }}>
                    Por favor, selecione apenas uma empresa para realizar o processo separadamente.
                    </p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        gap: '20px',
                        marginTop: '25px',
                        marginBottom: '20px',
                    }}
                >
                    <div
                        style={{
                            textAlign: 'center',
                            width: '40%',
                            borderRight: '1px solid #ccc',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingRight: '10px',
                        }}
                    >
                        <h3 style={{ fontSize: '1.2rem', marginBottom: '10px' }}>CATA</h3>
                        <ul style={{ listStyle: 'none', padding: 0, margin: 0, textAlign: 'left', alignSelf: 'stretch' }}>
                            {selectedCompanies
                                .filter((item) => item.company === 'cata')
                                .map((item) => (
                                    <li key={item.id}>{item.id}</li>
                                ))}
                        </ul>
                    </div>
                    <div
                        style={{
                            textAlign: 'center',
                            width: '40%',
                            borderLeft: '1px solid #ccc',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingLeft: '10px',
                        }}
                    >
                        <h3 style={{ fontSize: '1.2rem', marginBottom: '10px' }}>GADP</h3>
                        <ul style={{ listStyle: 'none', padding: 0, margin: 0, textAlign: 'left', alignSelf: 'stretch' }}>
                            {selectedCompanies
                                .filter((item) => item.company === 'gadp')
                                .map((item) => (
                                    <li key={item.id}>{item.id}</li>
                                ))}
                        </ul>
                    </div>
                </div>
            </Modal>

            <Row justify="end">
                {/*
                <Button
                    style={{
                        borderRadius: 20,
                        color: '#FFFFFF',
                        backgroundColor: props.nfNumber !== 0 ? '#FF6D6D' : '#BABBAF',
                        border: '1px solid #FFFFFF',
                        boxShadow: props.nfNumber !== 0 ? '0 0 0 5px #FF6D6D' : '0 0 0 5px #BABBAF',
                        width: 200,
                        marginRight: 20
                    }}
                >
                    Cancelar NF
                </Button>
                */}

                <Button
                    disabled={props.selectedItems.length === 0}
                    text="Solicitar Nota Fiscal"
                    nativeProps={{
                        ghost: true,
                        style: { marginRight: 10 }
                    }}
                    onClick={() => showNfProcess()}
                />

                <Button
                    text="Efetuar Pagamento"
                    disabled={props.selectedItems.length === 0}
                    onClick={() => showPaymentProcess()}
                />
            </Row>
            <Payer
                visible={showModalPayer}
                setVisible={setShowModalPayer}
                setDataPayerError={setDataPayerError}
                dataPayerError={dataPayerError}
                data={props.selectedItems}//{itemBase}
                allData={items}//{items}
                setReload={setReload}
                setItemBase={()=>console.log()}//
                setPagador={setPagador}
            />
            <AntModal
                data-testid="modal-form"
                destroyOnClose={true}
                forceRender
                title={
                    <Typography.Title style={{fontSize: 22}}>
                        Agendamentos com NF Emitida em Lote
                    </Typography.Title>
                }
                open={relatedModalOpen}
                centered={true}
                onOk={() => {setRelatedModalOpen(false);console.log('OK')}}
                onCancel={() => setRelatedModalOpen(false)}
                footer={[
                    <AntdButton
                        key="SALVAR1"
                        //type="primary"
                        htmlType="submit"
                        size="middle"
                        //loading={loading}
                        onClick={() => setRelatedModalOpen(false)}
                    >
                        Cancelar
                    </AntdButton>,
                    <AntdButton
                        key="SALVAR"
                        type="primary"
                        htmlType="submit"
                        size="middle"
                        //loading={loading}
                        onClick={() => {setRelatedModalOpen(false);handleSubmitFromRelatedInvoicesModal()}}
                    >
                        Continuar
                    </AntdButton>,
                ]}
            >
                <Typography.Paragraph>
                    Você selecionou um item com Nota emitida em Lote, para seguir com o pagamento é necessário adicionar os seguintes itens
                </Typography.Paragraph>
                {<br></br>}
                {getStringRelatedInvoice()}
                {<br></br>}
                Se continuar, os procedimentos acima serão automaticamente selecionados e direcionados para a tela de pagamento. Deseja prosseguir?
            </AntModal>
            {/* */}
            <AntModal
                data-testid="modal-form"
                destroyOnClose={true}
                forceRender
                title={
                    <Typography.Title style={{fontSize: 22, marginTop: 20}}>
                        Aviso: Dados do Tomador
                    </Typography.Title>
                }
                open={customerWarningModalOpen}
                centered={true}
                onOk={() => {setCustomerWarningModalOpen(false);console.log('OK')}}
                onCancel={() => setCustomerWarningModalOpen(false)}
                footer={[
                    <AntdButton
                        key="SALVAR1"
                        //type="primary"
                        htmlType="submit"
                        size="middle"
                        //loading={loading}
                        onClick={() => setCustomerWarningModalOpen(false)}
                    >
                        Cancelar
                    </AntdButton>,
                    <AntdButton
                        key="SALVAR"
                        type="primary"
                        htmlType="submit"
                        size="middle"
                        //loading={loading}
                        onClick={() => {setCustomerWarningModalOpen(false);handleSubmitFromCustomerWarningModal()}}
                    >
                        Continuar
                    </AntdButton>,
                ]}
            >
                <Typography.Paragraph>
                    Após efetuar o pagamento, não será possível editar os dados do tomador. Deseja prosseguir?
                </Typography.Paragraph>
                {/* {<br></br>}
                {getStringCustomerWarning()}
                {<br></br>} */}
            </AntModal>
        </div>
    )
}

export default ButtonsBar

import { useProcedure } from "@/@presentation/modules/procedure/context/procedure";
import { Button, Checkbox, List, Modal } from "antd";
import React from "react";

const SurgeonShow = (props: any) => {

  return (
    <Checkbox checked={props.surgeon.checked}>
      {props.surgeon.label}
    </Checkbox>
  )
};

interface IProps {}

export const SurgeonFilter: React.FC<IProps> = () => {
  const [selectedItems, setSelectedItems] = React.useState<{ id: string, name: string }[]>([]);
  const [openModal, setOpenModal] = React.useState(false);

  const unit_portal = JSON.parse(localStorage.getItem('@unit_portal') ?? '{}');

  const toggleSelect = (id: string, name: string) => {
    const isSelected = selectedItems.some(item => item.id === id);
    const updatedItems = isSelected
      ? selectedItems.filter((item) => item.id !== id)
      : [...selectedItems, { id, name }];

    setSelectedItems(updatedItems);
    try {
      localStorage.setItem('@surgeon_filtered', JSON.stringify(updatedItems));
    } catch (error) {
      console.error("Failed to update localStorage:", error);
    }
  };

  const {
    states: { doctors },
    actions: { handleInitLoadSearchGeneral },
  } = useProcedure();

  React.useEffect(() => {
    if (selectedItems.length > 0){
      localStorage.setItem('@surgeon_filtered', JSON.stringify(selectedItems));
    }
  }, [selectedItems]);

  React.useEffect(() => {
    handleInitLoadSearchGeneral();
  }, []);

  React.useEffect(() => {
    const storedSurgeons = localStorage.getItem('@surgeon_filtered');
    if (storedSurgeons) {
      try {
        const parsedSurgeons = JSON.parse(storedSurgeons);
        if (Array.isArray(parsedSurgeons)) {
          // Atualize apenas se os valores forem diferentes
          if (JSON.stringify(selectedItems) !== JSON.stringify(parsedSurgeons)) {
            setSelectedItems(parsedSurgeons);
          }
        } else {
          console.warn("Unexpected format for stored surgeons:", parsedSurgeons);
        }
      } catch (error) {
        console.error("Failed to parse stored surgeons:", error);
      }
    } else if (unit_portal?.unit_portal_surgeons?.length > 0) {
      const selectedSurgeons = unit_portal.unit_portal_surgeons.map((doctor: any) => ({
        id: doctor.surgeon_id,
        name: doctor.surgeon_name,
      }));
      // Atualize apenas se os valores forem diferentes
      if (JSON.stringify(selectedItems) !== JSON.stringify(selectedSurgeons)) {
        setSelectedItems(selectedSurgeons);
      }
    } else if (doctors && doctors.length > 0) {
      const selectedDoctors = doctors.map((doctor: any) => ({
        id: doctor.value,
        name: doctor.label,
      }));
      // Atualize apenas se os valores forem diferentes
      if (JSON.stringify(selectedItems) !== JSON.stringify(selectedDoctors)) {
        setSelectedItems(selectedDoctors);
      }
    }
  }, [doctors, unit_portal?.unit_portal_surgeons, selectedItems]);  

  function mergeDoctorsAndSurgeons() {
    if (unit_portal?.unit_portal_surgeons?.length > 0) {
      return unit_portal.unit_portal_surgeons.map((doctor: any) => ({
        label: doctor.surgeon_name,
        value: doctor.surgeon_id,
        checked: selectedItems.some(item => item.id === doctor.surgeon_id),
      }));
    } else {
      return doctors.map((doctor: any) => ({
        label: doctor.label,
        value: doctor.value,
        checked: selectedItems.some(item => item.id === doctor.value),
      }));
    }
  }

  return (
    <>
      <Modal
        title="Filtro geral de cirurgiões"
        open={openModal}
        closeIcon={null}
        footer={[
          <Button key="close" onClick={() => window.location.reload()}>
            Fechar
          </Button>,
        ]}
      >
        <div
          style={{
            maxHeight: '350px',
            overflowY: 'auto',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '5px',
          }}
        >
          <List
            dataSource={mergeDoctorsAndSurgeons()}
            renderItem={(item) => (
              <List.Item
                onClick={() => toggleSelect(item.value, item.label)}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <SurgeonShow surgeon={item} />
              </List.Item>
            )}
          />
        </div>
      </Modal>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 10,
        }}
      >
        <Button
          type="primary"
          style={{ width: '100%' }}
          onClick={() => setOpenModal(true)}
        >
          Cirurgiões ({selectedItems.length} selecionados)
        </Button>
      </div>
    </>
  );
};

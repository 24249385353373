import { Button, InputDefault } from '@/@presentation/components/form';
import { Col, Row, Form as AntForm, TimePicker, Select, Upload, InputNumber } from 'antd';
import React from 'react';
import { styles } from '../../styles';
import { ProcedureContextData, TSelect } from '@/@core/modules/procedure/domain/procedure.entities';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { file } from "@/@core/modules/file/infra/file.container.registry";
import { ENUM_PATH, ENUM_UPLOAD_TYPES } from "@/@core/modules/file/domain/gateway/file.gateway";
import { FormInstance } from 'antd/lib';
import moment from 'moment';
import { ConfirmationView } from '@/@presentation/modules/procedure/components/confirmationView';
import procedure from '@/@core/modules/procedure/infra/container.registry';
import { useParams } from 'react-router-dom';

interface IProps {
  states: ProcedureContextData['states'];
  handleSelectChargeType: (value: string) => void;
  handleUploadChange: (info: UploadChangeParam, id: number) => void;
  isHealthInsurance: boolean | null;
  isConfirm: boolean;
  form: FormInstance<any>;
}

export const Information: React.FC<IProps> = (props) => {
  const [defaultFileList, setDefaultFileList] = React.useState<any>();
  const [selectedCharge, setSelectedCharge] = React.useState<TSelect>();
  const [companies, setCompaniese] = React.useState<TSelect[]>([]);
  const [selectedCompany, setSelectedCompany] = React.useState('');
  const [plans, setPlans] = React.useState<TSelect[]>([]);
  const formItems = props.form.getFieldsValue()

  const { id } = useParams();

  const getDocuments = props.states?.data?.uploads?.find((item: any) => item.type === "TIIS")

  const handleSelectChargeType = (id: string) => {
    props.handleSelectChargeType(id)
    const selected = props.states.chargeMethods.find(item => item.value === id)
    setSelectedCharge(selected)
  }

  const onChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    const isSuccess = await file.save.execute({
      file: info.file,
      uploadType: ENUM_UPLOAD_TYPES.DOCUMENT,
      path: ENUM_PATH.PATIENT
    })
    if (isSuccess) {
      props.handleUploadChange(info, isSuccess.id)
    }

  };

  const handleCompanies = async () => {
    const allCompanies = await procedure.medicalProcedure.getCompanies()
    setCompaniese(allCompanies)
  }

  const handlePlans = async () => {
    const result = await procedure.medicalProcedure.getPlans(selectedCompany)
    setPlans(result)
  }

  const accommodation = [{ label: 'Apartamento', value: 'APARTMENT' }, { label: 'Enfermaria', value: 'NURSERY' }]

  const chargeValue = formItems?.charge && (typeof formItems.charge === 'object' ? formItems.charge.value : formItems.charge);
  const chargeMethod = props.states.chargeMethods.find(item => item.value === chargeValue);

  const chargeLabel = chargeMethod ? chargeMethod.label : (selectedCharge?.label || 'Valor não encontrado');


  React.useEffect(() => {
    getDocuments && setDefaultFileList([
      {
        uid: getDocuments.id,
        name: getDocuments.name,
        status: 'done',
        url: getDocuments.path,
      }
    ]);
  }, [])

  React.useEffect(() => {
    handleCompanies()
  }, []);

  React.useEffect(() => {
    selectedCompany && handlePlans()
  }, [selectedCompany])

  React.useEffect(() => {
    if (props.states.data?.schedule_payment) {
      setSelectedCompany(props.states.data?.schedule_payment?.insurance_id)
      props.handleSelectChargeType(props.states.data?.schedule_payment?.schedule_payment_type_id)
      const selected = props.states.chargeMethods.find((item) => item.value === String(props.states.data?.schedule_payment?.schedule_payment_type_id))
      setSelectedCharge(selected)
    }
  }, [props.states.data])

  //   const onRemove = async(fileToRemove: UploadFile) => {
  //      await file.remove.execute({id: fileToRemove.uid})
  //   };

  const formatterPrice = (value: any) => {
    if (!value) return `R$ 0.00`
    return `R$ ${Number(value ?? 0)?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`
  }

  return (
    <div>
      <Row gutter={[16, 16]} align="middle" justify="start">
        <Col xs={24} sm={12} md={6} style={{ marginBottom: 16 }}>
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'Duração estimada',
                value: formItems.durationEstimated
              }} />
            ) : (
              <AntForm.Item
                style={{

                }}
                name={'durationEstimated'}
                label="Duração estimada"
              >
                <input
                  type="time"
                  style={{
                    borderRadius: 8,
                    border: '1px solid #d9d9d9',
                    height: 40,
                  }}
                />
              </AntForm.Item>
            )
          }
        </Col>
        <Col xs={24} sm={12} md={6} style={{ marginBottom: 16 }}>
          {
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'Cobrança',
                value: chargeLabel
              }} />
            ) : (
              <AntForm.Item name="charge" label="Cobrança" rules={[{ required: true }]}>
                <Select
                  disabled={props.isConfirm}
                  data-testid="select-procedure-charge"
                  style={{ ...styles.defaultInput, minWidth: 260 }}
                  placeholder="Selecione"
                  onChange={handleSelectChargeType}
                  showSearch
                  filterOption={(input, option: any) => {
                    return (
                      option.value?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                      option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                      option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                    );
                  }}
                >
                  {
                    props.states.chargeMethods.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                </Select>
              </AntForm.Item>
            )
          }
        </Col>
        <Col xs={24} sm={24} md={6}>
          <div style={{ display: 'flex', justifyContent: 'center', marginLeft: 30 }}>
            <Upload
              disabled={props.isConfirm}
              accept=".pdf,image/*"
              beforeUpload={() => false}
              multiple={false}
              maxCount={1}
              fileList={defaultFileList}
              onChange={onChange}
              showUploadList={false}
            >
              {!props.isConfirm && (
                <Button
                  text="Anexar Guia TIIS"
                  nativeProps={{
                    style: {
                      marginBottom: 10,
                      // marginLeft: 30,
                    },
                    disabled: props.isConfirm,
                  }}
                />
              )}
            </Upload>
          </div>
        </Col>

      </Row>
      <Row gutter={[16, 16]} align="middle" justify="start">
        {
          selectedCharge?.label.toLowerCase().includes('convênio') && (
            <Row>
              <div style={{ marginRight: 10 }}>
                {props.isConfirm ?
                  <ConfirmationView item={{
                    label: 'Convênio',
                    value: companies.find((item) => item?.value === formItems?.healthInsuranceName)?.label
                  }} />
                  : <AntForm.Item name="healthInsuranceName" label="Convênio">
                    <Select
                      disabled={props.isConfirm}
                      data-testid="select-procedure-charge"
                      style={{ ...styles.defaultInput, minWidth: 260 }}
                      placeholder="Selecione"
                      onChange={e => setSelectedCompany(e)}
                      showSearch
                      filterOption={(input, option: any) => {
                        return (
                          String(option.value)?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                          option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                          option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {
                        companies.map((option) => (
                          <Select.Option key={option.value} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                    </Select>
                  </AntForm.Item>}
              </div>
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                {props.isConfirm ?
                  <ConfirmationView item={{
                    label: 'Plano',
                    value: plans.find((item) => item.value === formItems?.healthInsurancePlan)?.label
                  }} />
                  : <AntForm.Item name="healthInsurancePlan" label="Plano">
                    <Select
                      disabled={plans.length == 0}
                      data-testid="select-procedure-charge"
                      style={{ ...styles.defaultInput, minWidth: 260 }}
                      placeholder="Selecione"
                      showSearch
                      filterOption={(input, option: any) => {
                        return (
                          String(option.value).toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                          option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
                          option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {
                        plans.map((option) => (
                          <Select.Option key={option.value} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                    </Select>
                  </AntForm.Item>}
              </div>
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                {props.isConfirm ?
                  <ConfirmationView item={{
                    label: 'Carteirinha',
                    value: formItems.healthInsuranceCardCode
                  }} />
                  :
                  <InputDefault
                    placeholder='Carteirinha'
                    nativeProps={{
                      disabled: props.isConfirm
                    }}
                    label="Carteirinha"
                    name="healthInsuranceCardCode"
                  />}
              </div>
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                {props.isConfirm ?
                  <ConfirmationView item={{
                    label: 'Senha',
                    value: formItems.healthInsurancePassword
                  }} />
                  :
                  <InputDefault
                    placeholder='Senha'
                    nativeProps={{
                      disabled: props.isConfirm
                    }}
                    label="Senha"
                    name="healthInsurancePassword"
                  />}
              </div>
              <div style={{ marginLeft: 10 }}>
                {props.isConfirm ?
                  <ConfirmationView item={{
                    label: 'Acomodação',
                    value: accommodation.find((item) => item.value === formItems.healthInsuranceAccommodation)?.label
                  }} />
                  :
                  <AntForm.Item name="healthInsuranceAccommodation" label="Acomodação">
                    <Select
                      disabled={props.isConfirm}
                      data-testid="select-procedure-charge"
                      style={{ ...styles.defaultInput, minWidth: 260 }}
                      placeholder="Selecione"
                    >
                      {
                        accommodation.map((option) => (
                          <Select.Option key={option.value} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                    </Select>
                  </AntForm.Item>}
              </div>
            </Row>
          )
        }
        {
          (selectedCharge?.label.toLowerCase().includes('particular')
            ||
            selectedCharge?.label.toLowerCase().includes('reembolso'))
          && (
            props.isConfirm ? (
              <ConfirmationView item={{
                label: 'Valor',
                value: formatterPrice(formItems.price)
              }} />
            ) : (
              <AntForm.Item
                label="Valor"
                name="price"
              >
                <InputNumber
                  disabled={id ? true : false}
                  style={{
                    height: 40,
                    textTransform: 'uppercase',
                    marginTop: -15,
                    width: 150,
                    borderRadius: 20
                  }}
                  formatter={(value) => formatterPrice(value)}
                  parser={(value) => value && value?.replace(/\D/g, '') / 100}
                />
              </AntForm.Item>
            )
          )
        }
      </Row>
    </div>
  )
};

import React, { useEffect } from 'react';
import { Avatar, theme } from 'antd';
import { useAuthentication } from '@presentation/modules/authentication/contexts/authentication';
import word from '@core/modules/common/infra/word';
import style from './style';

const user_infos = localStorage.getItem('@unit_portal');
const companyNameSaved = JSON.parse(user_infos ? user_infos : '{}')?.name;
const user_infos_key = localStorage.getItem('@user_infos_key');
const isBackoffice = JSON.parse(user_infos_key ? user_infos_key : '{}').type === "BACKOFFICE";
const isPrivate = JSON.parse(user_infos ? user_infos : '{}')?.isPrivate;

const Profile: React.FC = () => {
    const { user } = useAuthentication();
    const [companyName, setCompanyName] = React.useState('');

    const translateUserType = (type) => {
        const types = {
            'BACKOFFICE': 'Backoffice',
            'SECRETARY': 'Secretária',
            'ANESTHETIST': 'Anestesista',
            'SURGEON': 'Cirurgião',
            'PATIENT': 'Paciente'
        };
        return types[type] || type;
    };


    
    useEffect(() => {
        if (companyNameSaved) {
            setCompanyName(companyNameSaved);
        }

        if (!companyNameSaved && !isBackoffice && !isPrivate) {
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
    }, []);

    const {
        token: { blue1, colorText },
    } = theme.useToken();

    return (
        <div style={style.container}>
            <Avatar style={style.avatar(blue1)} size={64} shape="square">
                {word.defaultName(word.getFirstTwoLetters(user?.name ?? ''))}
            </Avatar>
            <div style={style.containerInfo}>
                <span style={style.name}>
                    {word.defaultName(word.getFirstName(user?.name))}
                </span>
                <span style={style.type(colorText)}>{translateUserType(user?.type)}</span>
                <span style={style.type(colorText)}>{!isBackoffice && companyName}</span>
                <span style={style.email(colorText)}>{user?.email}</span>
            </div>
        </div>
    );
};

export default Profile;

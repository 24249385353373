import { Col, Typography, Row, Divider, Space, Tooltip, Input, message, notification } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
const { Text } = Typography;
import style from './style';

import { Schedule } from '@/@core/modules/schedule/domain/schedule.entities';
import { Button } from '@/@presentation/components/form';
import React from 'react';
import schedule from '@/@core/modules/schedule/infra/container.registry';
import { useProcedure } from '@/@presentation/modules/procedure/context/procedure';
import PaymentReceiptModal from '../modal/receipt';
import bill from '@/@core/modules/bill/infra/container.registry';

interface IDetails {
    data: Schedule | undefined;
}

const titleFormatted = (name: string, tussCode: string, xs: any) => {
    if (xs) return `${tussCode} - ${name.substring(0, 10)}... `;
    if (!xs && name.length > 60) return `${tussCode} - ${name.substring(0, 60)}... `;
    if (!xs) return `${tussCode} - ${name}`;
};

const ProcedureTags = (props: IDetails) => {
    const { xs } = useBreakpoint();
    return (
        <Space size={[0, 8]} wrap>
            {props?.data?.procedures?.map((procedure, index) => (
                <Tooltip
                    key={index}
                    placement={xs ? 'top' : 'leftBottom'}
                    title={`${procedure.name} TUSS CODE: ${procedure.tussCode}`}
                >
                    <span
                        key={index}
                        color={'#EDF6F9'}
                        style={{
                            color: '#054B7C',
                            fontSize: 16,
                            fontStyle: 'normal',
                            fontWeight: 50,
                            lineHeight: 'normal',
                            letterSpacing: 0.65,
                        }}
                    >
                        {titleFormatted(procedure.name, procedure.tussCode, xs)}
                    </span>
                </Tooltip>
            ))}
        </Space>
    );
};

const Details = (props: IDetails) => {
    const [isEditEmail, setIsEditEmail] = React.useState(false);
    const [emailIsValid, setEmailIsValid] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [openPaymentReceiptModal, setOpenPaymentReceiptModal] = React.useState(false);

    const { states } = useProcedure();

    const payment_billing: {
        last_email_sended: string;
        last_email_sended_date: string;
        url_billing: string;
        method_code: string;
        receipt: any;
    } = states.data?.payment_billing;

    console.log('payment_billing', payment_billing);

    const handleEmail = (text: string) => {
        setEmail(text)
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

        if (regex.test(text)) {
            setEmailIsValid(true)
        } else {
            setEmailIsValid(false)
        }
    }

    const handleSubmitEmail = async () => {
        const defineEmail = () => {
            if (email && emailIsValid) {
                return email
            } else {
                return payment_billing?.last_email_sended
            }
        }

        const sendedFor = defineEmail()

        if (!sendedFor) {
            return message.error(`O email ${sendedFor} é inválido!`)
        }


        await schedule.resendBilling.execute(sendedFor, props.data!.id).then(() => {
            message.success(`Email enviado para ${sendedFor} com sucesso!`)
        }).catch(() => {
            message.error('Erro ao enviar email!')
        });

        setEmail('')
        setEmailIsValid(false)

        // notificação que foi enviado
        setIsEditEmail(false)

        setTimeout(() => location.reload(), 2500)
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(String(payment_billing?.url_billing)).then(() => {
            message.success('Link em cópia na área de transferência!')
        })
    }

    function extractIdFromUrl(url: string) {
        const regex = /\/customer\/bills\/(\d+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
      }

    const deleteBilling = async () => {
        const id = extractIdFromUrl(payment_billing?.url_billing)

        if (!id) {
            return message.error('Erro ao cancelar link de pagamento!')
        }

        await bill.delete.execute(id).then((result) => {
            if (result) {
                notification.success({
                    message: 'Link de pagamento cancelado com sucesso!',
                });

                setTimeout(() => location.reload(), 2500)
            }

            if (!result) {
                notification.error({
                    message: 'Erro ao cancelar link de pagamento!',
                });
            }
        }).catch(() => {
            notification.error({
                message: 'Erro ao cancelar link de pagamento!',
            });
        });
    }

    function formatDate(date: Date): string {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses são 0-indexados
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}/${month}/${year} às ${hours}:${minutes}`;
    }    

    return (
        <Col xs={24} sm={24} md={14} lg={14} xl={14} style={{ paddingLeft: 40 }}>
            <PaymentReceiptModal visible={openPaymentReceiptModal} onClose={() => setOpenPaymentReceiptModal(false)} data={payment_billing?.receipt} />
            <Row style={style.rowTitle}>
                <Text style={style.title}>Detalhe do agendamento</Text>
                <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                    <Row style={{ marginBottom: 10, marginTop: 10 }}>
                        <Text style={style.procedureTitle}>Procedimento</Text>
                    </Row>
                    <Row style={style.procedureRow}>
                        <Text style={style.procedureTitle}>
                            <ProcedureTags data={props?.data} />
                        </Text>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Row>
                        <Space direction="horizontal" size="small">
                            <img height={27} width={27} src="/warning-blue.svg" />
                            <img height={27} width={27} src="/check.svg" />
                            <img height={27} width={27} src="/file-plus-blue.svg" />
                        </Space>
                    </Row>
                </Col>
                <Divider style={{ marginTop: 15 }} />
            </Row>

            <Row>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Row style={{ marginTop: -10, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Paciente</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>{props?.data?.patient}</Text>
                    </Row>

                    <Row style={{ marginTop: 54, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Valor</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>{props?.data?.props?.netValue ? `R$ ${Number(props?.data?.props?.netValue ?? 0)?.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}` : props?.data?.value}</Text>
                    </Row>

                    <Row style={{ marginTop: 54, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Nome do tomador da nota</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>
                            {props?.data?.receiverName !== ''
                                ? props?.data?.receiverName
                                : 'Não informado'}
                        </Text>
                    </Row>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Row style={{ marginTop: -10, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Status</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>{props?.data?.status}</Text>
                    </Row>

                    <Row style={{ marginTop: 54, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Valor pago</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>{props?.data?.amountPaid}</Text>
                    </Row>

                    <Row style={{ marginTop: 54, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Número da nota fiscal</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>
                            {(props?.data?.props?.invoiceNumber || props?.data?.taxNoteNumber) ?? 'Não informado'}
                        </Text>
                    </Row>
                </Col>
            </Row>
            <Divider />
            <Text style={style.title}>Detalhe do pagamento</Text>
            <Row style={{ marginTop: -60 }}>
                <Col>
                    <Row style={{ marginTop: 54, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Nome do pagador</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>
                            {props?.data?.props?.payerCustomer?.name !== undefined && props?.data?.props?.payerCustomer?.name !== ''
                                ? props?.data?.props?.payerCustomer?.name
                                : 'Não informado'}
                        </Text>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: -20 }}>
                <Col>
                    <Row style={{ marginTop: 20, marginBottom: 10 }}>
                        {
                            isEditEmail ? (
                                <Text style={style.procedureTitle}>Enviar novo email para:</Text>
                            ) : (
                                <Text style={style.procedureTitle}>Email enviado para:</Text>
                            )
                        }
                    </Row>
                    <Row style={{
                        ...style.rowTitle,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        {
                            isEditEmail ? (
                                <Input
                                    name='email'
                                    placeholder='Digite o novo email'
                                    required
                                    onChange={(e) => handleEmail(e.target.value)}
                                />
                            ) : (
                                <Text style={style.textDetails}>{payment_billing?.last_email_sended || 'Não encontrado'}</Text>
                            )
                        }
                    </Row>
                    <Row>
                        {
                            isEditEmail ? (
                                <>
                                    <Button
                                        text='Salvar e enviar'
                                        loading={false}
                                        size='small'
                                        disabled={!emailIsValid}
                                        onClick={() => handleSubmitEmail()}
                                    />
                                    <Button
                                        text='Cancelar'
                                        onClick={() => setIsEditEmail(false)}
                                        nativeProps={{
                                            ghost: true,
                                            style: {
                                                marginLeft: 15
                                            },
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <Button
                                        loading={false}
                                        text='Editar'
                                        size='small'
                                        disabled={!payment_billing?.last_email_sended}
                                        onClick={() => setIsEditEmail(true)}
                                        nativeProps={{
                                            style: {
                                                marginTop: 10
                                            }
                                        }}
                                    />
                                    <Button
                                        loading={false}
                                        text='Reenviar'
                                        size='small'
                                        disabled={!payment_billing?.last_email_sended}
                                        onClick={() => handleSubmitEmail()}
                                        nativeProps={{
                                            style: {
                                                marginTop: 10,
                                                marginLeft: 15
                                            }
                                        }}
                                    />
                                </>
                            )
                        }
                    </Row>
                </Col>
                <Col style={{ marginLeft: 30 }}>
                    <Row style={{ marginTop: 20, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Enviado em:</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>{!payment_billing?.url_billing ? 'Não encontrado' : (payment_billing?.last_email_sended_date ? formatDate(new Date(payment_billing?.last_email_sended_date)) : 'Não encontrado')}</Text>
                    </Row>
                </Col>
                <Col style={{ marginLeft: 30 }}>
                    <Row style={{ marginTop: 20, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Forma de pagamento:</Text>
                    </Row>
                    <Row style={style.rowTitle}>
                        <Text style={style.textDetails}>{payment_billing?.method_code ?? 'Não encontrado'}</Text>
                    </Row>
                    <Row>
                        <Button
                            loading={false}
                            text='Copiar link de pagamento'
                            size='small'
                            disabled={!payment_billing?.url_billing}
                            onClick={() => handleCopy()}
                            nativeProps={{
                                style: {
                                    marginTop: 10
                                }
                            }}
                        />
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: -20 }}>
                <Col>
                    <Row style={{ marginTop: 54, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Email do comprovante enviado para:</Text>
                    </Row>
                    <Row>
                    <Text style={style.textDetails}>
                        {payment_billing?.receipt?.dueAt === "Data não disponível" ? 'Comprovante não disponível' : (payment_billing?.receipt?.email || 'Não encontrado')}
                    </Text>
                    </Row>
                    <Row style={{ marginTop: 20, marginBottom: 10 }}>
                        <Col>
                        <Button
                            loading={false}
                            disabled={!payment_billing?.url_billing || !payment_billing?.receipt || payment_billing?.receipt?.dueAt === "Data não disponível" || !payment_billing?.receipt?.dueAt}
                            text='Comprovante de pagamento'
                            size='small'
                            onClick={() => setOpenPaymentReceiptModal(true)}
                            nativeProps={{
                                style: {
                                    marginTop: 10,
                                    marginLeft: 0
                                }
                            }}
                        />
                        </Col>
                        <Col style={{ marginLeft: 10 }}>
                            <Button
                                loading={false}
                                disabled={!payment_billing?.url_billing || payment_billing?.receipt?.dueAt !== "Data não disponível"}
                                text='Cancelar link de pagamento'
                                size='small'
                                onClick={() => deleteBilling()}
                                nativeProps={{
                                    style: {
                                        marginTop: 10,
                                        marginLeft: 0
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col style={{ marginLeft: 30 }}>
                    <Row style={{ marginTop: 54, marginBottom: 10 }}>
                        <Text style={style.procedureTitle}>Enviado em:</Text>
                    </Row>
                    <Row>
                    <Text style={style.textDetails}>
                        {payment_billing?.receipt?.dueAt || 'Não encontrado'}
                    </Text>
                    </Row>
                </Col>
            </Row>
            <Divider />
        </Col>
    );
};

export default Details;

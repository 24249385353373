import React from 'react';
import { useParams, useNavigate, NavigateFunction } from 'react-router-dom';

import { Form as AntForm, Col, Typography, Row, Flex, Divider, Select } from 'antd';

import { Notification } from '@presentation/components/common';
import {
    validateMessages,
    Loading,
    InputDefault,
    InputPhone,
    initialize,
    InputEmail,
    SelectUserType,
    SelectSurgeon,
    Button,
} from '@presentation/components/form';
import user from '@core/modules/user/infra/container.registry';
import { TCreateOrUpdate } from '@core/modules/user/domain/user.entities';
import style from './style';
import { formattedRoute, routes } from '../../../routes';

const { Text } = Typography;

const NotFound = (navigate: NavigateFunction) => {
    Notification.error({
        message: 'Usuário não encontrado!',
    });
    navigate(formattedRoute(routes.ADMINISTRATION_KEY, 'user'));
};

function UserForm() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = React.useState(false);
    const [form] = AntForm.useForm<TCreateOrUpdate>();
    const [data, setData] = React.useState<any>();
    const [surgeons, setSurgeons] = React.useState<any>(undefined);

    const onFinish = async (values: TCreateOrUpdate) => {
        const surgeonIdsParse = surgeons?.map((item: string) => parseInt(item, 10));
        try {
            setLoading(true);
            if (id) {
                await user.update.execute({
                    ...values,
                    id,
                    surgeonIds: surgeonIdsParse,
                });
            } else {
                await user.create.execute({
                    ...values,
                    surgeonIds: surgeonIdsParse,
                });
            }
            Notification.success({
                message: id
                    ? 'Dados do usuário alterados com sucesso!'
                    : 'Usuário criado com sucesso!',
            });
            form.resetFields();
            navigate(formattedRoute(routes.ADMINISTRATION_KEY, 'user'));
        } catch (error: any) {
            Notification.error({
                message: error.message,
            });
        } finally {
            setLoading(false);
        }
    };

    const loadData = React.useCallback(async () => {
        if (id) {
            setLoading(true);
            try {
                const result: any = await user.get.execute({ id: id });
                setData(result);
                if (!result?.person?.email) {
                    NotFound(navigate);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
    }, [id]);

    React.useEffect(() => {
        loadData();
    }, [loadData]);

    React.useEffect(() => {
        const formatData = {
            id: data?.id,
            ...data?.person,
            surgeons: data?.surgeons,
        };
        initialize(setLoading, form, formatData);


    }, [data]);

    const title = id ? 'Editar' : 'Cadastrar';

    return (
        <div style={{ width: '90%', margin: 10 }}>
            <Row>
                <Text style={style.title}>Usuário</Text>
            </Row>
            <Row>
                <Text style={style.subTitle}>Dados do cirurgião</Text>
            </Row>

            <Loading value={loading}>
                <AntForm
                    initialValues={data}
                    name="form-user-edit"
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    validateMessages={validateMessages}
                    autoComplete="off"
                    style={{ margin: 40 }}
                >
                    <Text style={style.dataTitle}>{title} secretária(o)</Text>

                    <Flex wrap="wrap" gap="large" style={{ marginTop: 10 }}>
                        <Col xs={24} sm={11}>
                            <InputDefault
                                name="name"
                                placeholder="Digite o nome "
                                required={true}
                            />
                        </Col>
                        <Col xs={24} sm={11}>
                            <InputEmail name="email" label="Email" required={true} style={{ marginBottom: 12, paddingBottom: 12 }} />
                        </Col>
                    </Flex>
                    <Flex wrap="wrap" gap="large">
                        <Col xs={24} sm={11}>
                            <InputPhone
                                name="phone"
                                placeholder="Digite o telefone "
                                required={true}
                            />
                        </Col>
                        <Col xs={24} sm={11}>
                            <SelectUserType />
                        </Col>
                    </Flex>

                    <Flex wrap="wrap" gap="large">
                        <Col xs={24} sm={11}>
                            <AntForm.Item name="surgeons" label="Cirurgião">
                                {
                                    data?.props?.unitName ? <Text style={{
                                        color: 'red',
                                        fontSize: 12,
                                        marginBottom: 10,

                                    }}>Usuário faz parte da unidade {data?.props?.unitName}, portanto o vinculo de cirurgiões será com base na unidade.</Text>
                                    : (
                                    <SelectSurgeon
                                    defaultValue={data?.surgeons?.map((item: any) => item.id?.toString())}
                                    setSurgeons={setSurgeons}
                                    defaultName={data?.surgeons || []}
                                />
                                    )
                                
                                }
                                
                            </AntForm.Item>
                        </Col>
                    </Flex>

                    <Divider />

                    <Row style={{ margin: 30 }}>
                        <Col style={{ width: '50%' }}>
                            <Button
                                text='Voltar'
                                nativeProps={{
                                    type: 'text',
                                    ghost: true,
                                }}
                                key="submit"
                                data-testid="form-btn-back"
                                type="text"
                                loading={loading}
                                onClick={() => navigate('/administration/user')}
                            />
                        </Col>
                        <Col style={{ width: '50%', textAlign: 'right' }}>
                            <Button
                                text='Salvar'
                                nativeProps={{
                                    htmlType: 'submit',
                                    style: {
                                        marginBottom: '5%'
                                    }
                                }}
                                type="primary"
                                data-testid="form-btn-save"
                                loading={loading}
                            />
                        </Col>
                    </Row>
                </AntForm>
            </Loading>
        </div>
    );
}

export default UserForm;

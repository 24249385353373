import { Row, Typography, Form as AntForm, Select, FormInstance } from 'antd';
import React from 'react';
import { styles } from '../../styles';
import { TSelect } from '@/@core/modules/procedure/domain/procedure.entities';
import { ConfirmationView } from '@/@presentation/modules/procedure/components/confirmationView';

interface IProps {
  handleAddProcedure: (code: string, id?: string) => void;
  tuss: TSelect[];
  procedures: TSelect[];
  doctors: TSelect[];
  isConfirm: boolean;
  form: FormInstance<any>;
}

const surgeonsFiltered = JSON.parse(localStorage.getItem('@surgeon_filtered') ?? '[]');

export const ProceduresComponentForm: React.FC<IProps> = (props) => {
  const formItems = props.form.getFieldsValue();

  const isBackoffice = JSON.parse(localStorage.getItem('@user_infos_key') ?? '{}').type === 'BACKOFFICE';

  const filterOption = (input: any, option: any) => {
    return (
      option.value?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
      option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0 ||
      option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
    );
  };

  return (
    <div>
      <Typography style={styles.typography}>Procedimentos</Typography>
      <Row
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ marginRight: 10 }}>
          {props.isConfirm ? (
            <ConfirmationView
              item={{
                label: 'Nome do cirurgião',
                value: props.doctors.find(
                  (item) =>
                    item.value === formItems?.doctorName || item.value === formItems?.doctorName?.value
                )?.label,
              }}
            />
          ) : (
            <AntForm.Item name="doctorName" label="Nome do cirurgião" rules={[{ required: true }]}>
              <Select
                disabled={props.isConfirm}
                data-testid="select-doctorName"
                style={{ ...styles.defaultInput, minWidth: 260 }}
                placeholder="Selecione"
                showSearch
                filterOption={filterOption}
              >
                {isBackoffice &&
                  props.doctors.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}

                {!isBackoffice &&
                  surgeonsFiltered.map((option: { id: string; name: string }) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
              </Select>
            </AntForm.Item>
          )}
        </div>
        {!props.isConfirm && (
          <div style={{ marginRight: 10 }}>
            <AntForm.Item name="tussCode" label="Código TUSS">
              <Select
                disabled={props.isConfirm}
                data-testid="select-tussCode"
                style={{ ...styles.defaultInput, minWidth: 200 }}
                placeholder="Selecione"
                showSearch
                filterOption={filterOption}
                onSelect={(code) => props.handleAddProcedure(code, undefined)}
              >
                {props.tuss.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </AntForm.Item>
          </div>
        )}
        {!props.isConfirm && (
          <div style={{ marginRight: 10 }}>
            <AntForm.Item name="procedures" label="Procedimento">
              <Select
                disabled={props.isConfirm}
                data-testid="select-procedures"
                style={{ ...styles.defaultInput, minWidth: 400 }}
                placeholder="Selecione"
                showSearch
                filterOption={filterOption}
                onSelect={(code) => props.handleAddProcedure(code, undefined)}
              >
                {props.procedures.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </AntForm.Item>
          </div>
        )}
      </Row>
    </div>
  );
};

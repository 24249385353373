import { Modal } from "antd";

const PaymentReceiptModal = ({ visible, onClose, data }: {
  visible: boolean;
  onClose: () => void;
  data: {
    payerName?: string;
    scheduleIds?: string;
    dates?: string;
    dueAt?: string;
    paymentType?: string;
    installments?: number;
    grossValue?: string;
    netValue?: string;
    bplId?: number;
  };
}) => {
  return (
    <Modal
      title="Comprovante de Pagamento"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={700}
    >
      <div style={{ fontFamily: "Arial, sans-serif", backgroundColor: "#fff", padding: "20px" }}>
        {/* Cabeçalho */}
        <div style={{ textAlign: "center", color: "#2E6CA5", marginBottom: "20px" }}>
          <h2>Comprovante de Pagamento</h2>
          <p><strong>Takaoka Anestesia – { data?.bplId === 3 ? 'CATA' : 'GADP' }</strong></p>
          <p>
            {
              data?.bplId === 3 ?
              'CATA CLÍNICA DE ANESTESIA E SERVIÇOS MÉDICOS EIRELI' :
              'GADP CLÍNICA DE SERVIÇOS MÉDICOS LTDA'
            }
          </p>
          <p>CNPJ { data?.bplId === 3 ? '53.371.035/0001-01' : '08.706.390/0001-21' }</p>
        </div>

        {/* Informações do pagamento */}
        <div style={{ marginBottom: "20px" }}>
          <p><strong>Nome do Pagador:</strong> {data?.payerName}</p>
          <p><strong>ID dos Procedimentos:</strong> {data?.scheduleIds}</p>
          <p><strong>Serviços Prestados:</strong> {data?.dates}</p>
          <p><strong>Data de Pagamento:</strong> {data?.dueAt}</p>
          <p><strong>Forma de Pagamento:</strong> {data?.paymentType}</p>
          <p><strong>Parcelado:</strong> {data?.installments}x</p>
        </div>

        {/* Tabela de valores */}
        <div style={{ marginBottom: "20px" }}>
          <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "10px" }}>
            <thead>
              <tr style={{ backgroundColor: "#f4f4f4" }}>
                <th style={{ border: "1px solid #ddd", padding: "10px", textAlign: "left" }}>Valor Bruto da NF</th>
                <th style={{ border: "1px solid #ddd", padding: "10px", textAlign: "left" }}>Valor Pago da NF</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: "1px solid #ddd", padding: "10px" }}>{data?.grossValue}</td>
                <td style={{ border: "1px solid #ddd", padding: "10px" }}>{data?.netValue}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Rodapé */}
        <div style={{ textAlign: "center", fontSize: "12px", color: "#5C5C5C", marginTop: "20px" }}>
          <p>Takaoka Anestesia</p>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentReceiptModal;

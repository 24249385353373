import React, { useEffect, useState } from 'react';
import { Form, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { Notification } from '@presentation/components/common';
import { List, DataType, getFieldsForFilter, getPageAndPerPage } from '@presentation/components/form';
import Header from './header';

import schedule from '@core/modules/schedule/infra/container.registry';
import { Schedule } from '@core/modules/schedule/domain/schedule.entities';
import ButtonsBar from '@/@presentation/components/form/ButtonsBar';
import Status from './status';
import { Receiver } from './receiver';
import StepsButton from './stepsButton';
import { ProcedureValueUpdate } from './procedureValueUpdate';
import { useItemsContext } from '@/@presentation/contexts/schedulesContext';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { auth } from '@/@core/modules/authentication/infra/container.registry';
import { Payer } from './payer';
import { CloseOutlined } from '@ant-design/icons';

const HomeList = () => {
    const navigate = useNavigate();

    const { setOrigin } = useItemsContext()

    setOrigin('')

    const [modalVisible, setModalVisible] = React.useState(false);
    const [receiverVisible, setReceiverVisible] = React.useState(false);
    const [payerVisible, setPayerVisible] = React.useState(false);
    const [dataPayerError, setDataPayerError] = useState(false);
    const [pagador, setPagador] = useState('')
    
    const [params, setParams] = React.useState<Schedule>();
    const [loading, setLoading] = React.useState(false);
    const [isReload, setReload] = React.useState(true);
    const [currentStep, setCurrentStep] = React.useState(0);
    const [onlyRead, setOnlyRead] = React.useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<string[]>([]);
    const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

    const [customerSelected, setSelectedCustomer] = useState<any>()
    const [payerSelected, setPayerSelected] = useState<any>()
    const [isBackoffice, setIsBackoffice] = useState(false);

    const [executeFunction, setExecuteFunction] = useState('');

    const [viewProcedureValue, setViewProcedureValue] = useState(false);

    const getUser = async () => {
        const userInfos = await auth.userInfos.execute();
        setIsBackoffice(userInfos?.props.type === 'BACKOFFICE');
    }

    const onChangeRowKey = (newSelectedRowKeys: React.Key[], newSelectedRows: Schedule[]) => {
        //newSelectedRowKeys só leva em consideração a pagina atual
        const removedRowKeys = selectedRowKeys.filter(key => !newSelectedRowKeys.includes(key));
        const removedItems = selectedItems.filter(item => removedRowKeys.includes(item.id));

        const addedRowKeys = newSelectedRowKeys.filter(key => !selectedRowKeys.includes(key));
        const addedItems = newSelectedRows.filter(item => !selectedItems.some(selectedItem => selectedItem.id === item.id));

        const updatedRowKeys = [...selectedRowKeys.filter(key => !removedRowKeys.includes(key)), ...addedRowKeys];
        const updatedItems = [...selectedItems.filter(item => !removedItems.some(removedItem => removedItem.id === item.id)), ...addedItems];
    
        setSelectedRowKeys(updatedRowKeys);
        setSelectedItems(updatedItems);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onChangeRowKey,
        preserveSelectedRowKeys: true,
        getCheckboxProps: (record: any) => ({
            disabled: (!record.canEdit && !isBackoffice) || record.schedulePaymentTypeId === 4,
        }),
    };

    const onChangeStep = (value: number) => {
        setCurrentStep(value);
    };

    async function loadData(params?: any) {
        params.ignoreCanceled = 'true'
        return await schedule.getAll.execute({
            ...getPageAndPerPage(params),
            ...getFieldsForFilter(params),
        });
    }

    const checkIsSelected = (id: string) => {
        return selectedRowKeys.includes(id);
    }

    const rowClickProps = (record: any, columnIndex: number) => {

        const isSelected = checkIsSelected(record.id);

        if (isSelected) {
            Notification.error({
                message: `Por favor, desmarque o ID ${record.id} para realizar a alteração.`,
                duration: 2,
            });

            return;
        }

        const isColumnValue = columnIndex === 2

        const disabledWithNF = record?.statusInvoice === 'Emitido' || record?.statusInvoice === 'Solicitado'
        if (disabledWithNF && isColumnValue) {
            Notification.error({
                message: 'Não é possivel alterar o valor desse item!',
                duration: 2,
            });

            return;
        }

        if (columnIndex === 1) {
           
            if (
                record?.statusBilling !== 'pending' && 
                record?.statusBilling !== 'paid' &&
                record?.schedulePaymentTypeId !== 5 && 
                record?.statusInvoice !== 'Emitido' &&
                record?.statusInvoice !== 'Solicitado' &&
                record?.status !== 'canceled' 
            
            )
            {
                setOnlyRead(false)
                setSelectedCustomer(record)
                setReceiverVisible(true)
            }else{
                setOnlyRead(true)
                setSelectedCustomer(record)
                setReceiverVisible(true)
                /*
                Notification.error({
                    message: 'Não é possivel alterar o tomador desse item!',
                    duration: 2,
                });
                */
            }
        } else if (columnIndex === 2) {
            if (
                record?.statusBilling !== 'pending' &&
                record?.statusBilling !== 'paid' &&
                record?.schedulePaymentTypeId !== 5 &&
                record?.statusInvoice !== 'Emitido' &&
                record?.statusInvoice !== 'Solicitado' &&
                record?.status !== 'canceled' ||
                (
                    record?.total != 'R$ 0,00' &&
                    record?.updatedBySecretary === true && // @TODO - aqui que bloqueia o valor
                    record?.statusBilling !== 'paid' &&
                    record?.statusBilling !== 'pending' &&
                    record?.schedulePaymentTypeId !== 5
                )
            ) {
                setSelectedCustomer(record)
                setViewProcedureValue(true)
            } else {
                Notification.error({
                    message: 'Não é possivel alterar o valor desse item!',
                    duration: 2,
                });
            }
        } 
        else if (columnIndex === 3) {
            setPayerSelected(record)
            setPayerVisible(true)
        } else {
            setOrigin('payment')
            navigate(`/schedule/${record?.id}`, {
                state: {canEdit: record?.canEdit},
            });
        }
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'ID ',
            dataIndex: 'id',
            width: 80,
            align: 'center',
            render: (id) => (
                <a>{id}</a>
            ),
            onCell: (record, rowIndex) => {
                return {
                    onClick: (e) => {
                        e.stopPropagation();
                        rowClickProps(record, 0);
                    },
                };
            },
        },
        {
            title: 'Data Procedimento ',
            dataIndex: 'procedureDate',
            sortDirections: ['descend'],
            width: 120,
            align: 'center',
        },
        // {
        //     title: 'Hora Procedimento ',
        //     dataIndex: 'procedureHour',
        //     sortDirections: ['descend'],
        //     align: 'center',
        //     render: (procedureHour) => (
        //         <p>{String(procedureHour).slice(8, -3)}</p>
        //     ),
        // },
        {
            title: 'Paciente',
            dataIndex: 'patient',
            defaultSortOrder: 'descend',
            responsive: ['sm'],
            render: (unit) => (
                <Tooltip placement="topLeft" title={unit}>
                    {unit?.length > 20 ? `${unit.substring(0, 20)}...` : unit}
                </Tooltip>
            ),
        },
        {
            title: 'Unidade',
            dataIndex: 'unit',
            defaultSortOrder: 'descend',
            responsive: ['sm'],
            render: (unit) => (
                <Tooltip placement="topLeft" title={unit}>
                    {unit?.length > 20 ? `${unit.substring(0, 20)}...` : unit}
                </Tooltip>
            ),
        },
        {
            title: 'Cirurgião',
            dataIndex: 'surgeon',
            defaultSortOrder: 'descend',
            responsive: ['sm'],
            render: (surgeon) => (
                <Tooltip placement="topLeft" title={surgeon}>
                    {surgeon?.length > 23 ? `${surgeon.substring(0, 23)}...` : surgeon}
                </Tooltip>
            ),
        },
        {
            title: 'Procedimento',
            dataIndex: 'procedure',
            defaultSortOrder: 'descend',
            render: (procedure) => (
                <Tooltip placement="topLeft" title={procedure}>
                    {procedure?.length > 26 ? `${procedure.substring(0, 26)}...` : procedure}
                </Tooltip>
            ),
        },
        {
            title: 'Valor',
            dataIndex: 'total',
            defaultSortOrder: 'descend',
            responsive: ['sm'],
            render: (total, record: any) => {
                // let color = '#1677ff';
                // if (total === 'R$ 0,00')
                //     color = '#ed0707'
                if (record.canEdit || isBackoffice) {
                    return <a><EditOutlined /> {total}</a>
                }

                return <p>{total}</p>
            },
            onCell: (record: any, rowIndex) => {
                return {
                    onClick: (e) => {
                        if (record.canEdit || isBackoffice) {
                            e.stopPropagation();
                            rowClickProps(record, 2);
                        }
                    },
                };
            },
        },
        {
            title: 'Nome do Tomador',
            dataIndex: 'receiverName',
            defaultSortOrder: 'descend',
            width: 100,
            responsive: ['sm'],
            render: (receiverName, record: any) => {
                // let color = '#1677ff';
                // if (record.paymentCustomer.id === 0)
                //     color = '#ed0707' 
                if (record?.canEdit || isBackoffice) {
                    return <a><EditOutlined /> {receiverName}</a>
                }

                return <p>{receiverName}</p>
            },
            onCell: (record: any, rowIndex) => {
                return {
                    onClick: (e) => {
                        if (record.canEdit || isBackoffice) {
                            e.stopPropagation();
                            rowClickProps(record, 1);
                        }
                    },
                };
            },
        },
        {
            title: 'Nome do Pagador',
            dataIndex: 'payerName', 
            defaultSortOrder: 'descend',
            width: 100,
            responsive: ['sm'],
            render: (payerName) => (
                payerName ? (
                    <a><EyeOutlined /> {payerName}</a>
                ) : <a>-</a>
            ),
            onCell: (record: any, rowIndex) => {
                return {
                    onClick: (e) => {
                        if(record.payerCustomer && record.payerCustomer !== undefined && Object.keys(record.payerCustomer).length > 0){
                            e.stopPropagation();
                            rowClickProps(record, 3); 
                        }
                            
                    },
                };
            },
        },
        {
            title: 'Forma de Pagamento',
            defaultSortOrder: 'descend',
            width: 100,
            align: 'center',
            responsive: ['sm'],
            render: (item) => {
                if (item?.schedulePaymentTypeId === 4) {
                    return 'CONVÊNIO'
                } else {
                    return (
                        <Tooltip placement="topLeft" title={!item?.methodCode? '-' : item?.methodCode === 'bank_slip' ? `BOLETO` : item?.methodCode === 'credit_card' ? 'CRÉDITO' : item?.methodCode.toUpperCase()} style={{textTransform: 'uppercase'}}>
                    {!item?.methodCode? '-' : item?.methodCode === 'bank_slip' ? `BOLETO` : item?.methodCode === 'credit_card' ? 'CRÉDITO' : item?.methodCode.toUpperCase()}
                </Tooltip>
                    )
                }
            },
        },
        {
            title: 'Status Pagamento',
            dataIndex: '',
            defaultSortOrder: 'descend',
            width: 100,
            render: (record) => (
                <Status key={record?.id} item={record} />
            ),

            responsive: ['sm'],
        },
        {
            title: 'Baixar NFe',
            align: 'center',
            width: 100,
            render: (record) => (
                <img
                    key={record?.id}
                    src={record?.invoiceUrl ? "/pdfIcon.svg" : "/pdfIconGray.svg"}
                    onClick={() => {
                        if (record?.invoiceUrl) {
                            window.open(record.invoiceUrl.replace('$1', '?'), '_blank');
                        }
                    }}
                    style={{
                        cursor: record?.invoiceUrl ? 'pointer' : 'not-allowed',
                        height: 23,
                        width: 23,
                        alignContent: 'center',
                        alignItems: 'center',
                        opacity: record?.invoiceUrl ? 1 : 0.5,
                    }}
                />
            ),
            responsive: ['sm'],
        },
        {
            title: 'Botões de Ação',
            dataIndex: '',
            key: 'x',
            align: 'center',
            responsive: ['sm'],
            render: (record) => (
                <StepsButton
                    id={record?._id}
                    onChange={onChangeStep}
                    current={currentStep}
                    item={record} />
            ),
        },
    ]

    const removeItem = (ids: string | string[], callback?: 'nf' | 'payment' | '') => {
        const idsToRemove = Array.isArray(ids) ? ids : [ids];
    
        const idsToRemoveSet = new Set(idsToRemove);

        const updatedItems = selectedItems.filter(item => !idsToRemoveSet.has(item.id));
        setSelectedItems(updatedItems);
    
        const updatedKeys = selectedRowKeys.filter((key: string) => !idsToRemoveSet.has(key));
        setSelectedRowKeys(updatedKeys);

        if (callback !== undefined && callback !== null) {
            setExecuteFunction(callback);
        }
    }

    useEffect(() => {
        getUser()
    }, [loading])
    
    useEffect(() => {
        setSelectedItems([]);
        setSelectedRowKeys([]);
    }, []);

    const selectedView = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginBottom: 10 }}>
                <span style={{ color: '#1890ff', fontSize: 14, fontWeight: 'bold', marginBottom: 15, marginLeft: 10 }}>
                    {selectedItems.length} item(s) selecionado(s)
                </span>
                <div>
                    {selectedItems.map((item, index) => (
                        <span key={index} style={{
                            backgroundColor: '#1890ff',
                            fontSize: 14,
                            fontWeight: 'bold',
                            color: '#fff',
                            padding: 5,
                            borderRadius: 5,
                            margin: 5,
                            marginTop: 10,
                        }}>
                            {item.id} <CloseOutlined
                                onClick={() => removeItem(item.id)}
                                style={{
                                    cursor: 'pointer',
                                }} />
                        </span>
                    ))}
                </div>
            </div>
        )

    }

    return (
        <>
            <Receiver
                visible={receiverVisible}
                setVisible={setReceiverVisible}
                onlyRead={onlyRead}
                data={customerSelected}
                setReload={setReload}
                setItemBase={() => console.log()}
            />
            <Payer
                visible={payerVisible}
                setVisible={setPayerVisible}
                data={[payerSelected]}//{itemBase}
                onlyRead={true}
                setReload={setReload}
                setItemBase={()=>console.log()}//
                setDataPayerError={setDataPayerError}
                dataPayerError={dataPayerError}
                setPagador={setPagador}
            />
            <ProcedureValueUpdate
                visible={viewProcedureValue}
                setVisible={setViewProcedureValue}
                data={customerSelected}
                setReload={setReload}
            />

            <div>
                <List
                    rowSelection={rowSelection}
                    columns={columns}
                    loadData={loadData}
                    Form={Form}
                    setLoading={setLoading}
                    loading={loading}
                    setModalVisible={setModalVisible}
                    modalVisible={modalVisible}
                    setParams={setParams}
                    params={params}
                    setReload={setReload}
                    isReload={isReload}
                    showStatusBillingFilter
                    headerChildren={<Header hideBtnSchedule={true} />}
                    selectedKeyView={selectedView}
                />

                <ButtonsBar
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    nfNumber={0}
                    removeItem={removeItem}
                    executeFunction={executeFunction}
                />
            </div>
        </>
    );
};

export default HomeList;
